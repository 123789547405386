import { FC } from 'react'
import { EightLogoProps } from 'components/EightLogo/types'

export const EightLogo: FC<EightLogoProps> = (props) => {
	return (
		<svg
			width={'100%'}
			viewBox="0 0 107 49"
			fill="none"
			className={props.className}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.3263 37.5413C21.3263 39.1709 20.0844 40.4925 18.5529 40.4925H10.8194C9.28795 40.4925 8.04688 39.1709 8.04688 37.5413V32.0645C8.04688 30.4348 9.28795 29.1133 10.8194 29.1133H18.5529C20.0844 29.1133 21.3263 30.4348 21.3263 32.0645V37.5413ZM8.04688 10.209C8.04688 8.57847 9.28795 7.25781 10.8194 7.25781H18.5529C20.0844 7.25781 21.3263 8.57847 21.3263 10.209V14.1718C21.3263 15.8024 20.0844 17.123 18.5529 17.123H10.8194C9.28795 17.123 8.04688 15.8024 8.04688 14.1718V10.209ZM29.3595 14.4972V8.17112C29.3595 3.65564 25.9241 0 21.6807 0H7.67959C3.4362 0 0 3.65564 0 8.17112V14.4972C0 17.8236 1.85581 20.6573 4.51799 21.9374V23.1071C1.85581 24.3871 0 27.2385 0 30.5472V39.5967C0 44.1122 3.4362 47.7687 7.67959 47.7687H21.6807C25.9241 47.7687 29.3595 44.1122 29.3595 39.5967V30.5472C29.3595 27.2385 27.5045 24.3871 24.8415 23.1071V21.9374C27.5045 20.6573 29.3595 17.8059 29.3595 14.4972Z"
				fill={props.color || 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M64.8594 44.1487H76.1427V40.1961H68.6975V37.1505H74.6847V33.2794H68.6975V30.2329H76.1427V26.2812H64.8594V44.1487Z"
				fill={props.color || 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M87.8134 11.3943H83.8985V4.23047H79.9844V22.0979H83.8985V15.3459H87.8134V22.0979H91.7284V4.23047H87.8134V11.3943Z"
				fill={props.color || 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M80.0547 44.1487H91.338V40.1961H83.8929V37.1505H89.88V33.2794H83.8929V30.2329H91.338V26.2812H80.0547V44.1487Z"
				fill={props.color || 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M101.628 34.1052H98.7109V30.0703H101.628C102.549 30.0703 103.239 30.3998 103.239 31.9647C103.239 33.4454 102.702 34.1052 101.628 34.1052ZM102.091 26.2812H94.875V44.1496H98.7132V37.8094H102.091C103.933 37.8094 107.003 36.6563 107.003 32.0458C107.003 27.1049 104.009 26.2812 102.091 26.2812Z"
				fill={props.color || 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M70.3854 15.2661H72.6879C72.6879 17.9008 71.8442 18.5606 70.2318 18.5606C68.4667 18.5606 67.8525 17.4899 67.8525 15.2661V9.9143C67.8525 8.43176 68.8506 7.52666 70.2318 7.52666C71.8442 7.52666 72.4576 8.59739 72.4576 9.9143H76.4493C76.4493 6.86776 74.5306 3.82031 70.2318 3.82031C65.857 3.82031 63.9375 6.12649 63.9375 9.9143V15.2661C63.9375 18.3126 64.8589 22.2652 70.2318 22.2652C75.3744 22.2652 76.5261 18.8892 76.5261 15.4317V11.9725H70.3854V15.2661Z"
				fill={props.color || 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M42.9064 33.3673C41.0636 33.0388 40.6038 32.1328 40.6038 31.5563C40.6038 30.4041 41.3716 29.8275 42.676 29.8275C44.1348 29.8275 44.5179 30.815 44.5179 31.3915H48.2025C48.2025 29.3334 46.8972 25.875 42.676 25.875C37.9941 25.875 36.7656 29.1677 36.7656 31.6386C36.7656 34.5204 38.9155 36.3315 42.0618 36.8257C43.1367 36.9904 44.6715 37.3199 44.6715 38.8024C44.6715 39.9546 44.0581 40.6959 42.676 40.6959C41.7547 40.6959 40.6038 40.5311 40.6038 38.3082H36.7656C36.7656 40.5311 37.3031 44.4004 42.676 44.4004C47.9722 44.4004 48.5864 40.3664 48.5864 38.7201C48.5864 35.2617 46.4374 34.0272 42.9064 33.3673Z"
				fill={props.color || 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M48.2833 18.0642H40.8373V15.0177H46.8245V11.1466H40.8373V8.10097H48.2833V4.14844H37V22.0159H48.2833V18.0642Z"
				fill={props.color || 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M55.728 26.2812H51.8906V44.1487H61.3321V40.0314H55.728V26.2812Z"
				fill={props.color || 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M98.7085 22.0979H102.699V8.34773H106.23V4.23047H95.1016V8.34773H98.7085V22.0979Z"
				fill={props.color || 'black'}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M54.5757 18.2286H51.8893V22.0979H61.1763V18.2286H58.4139V8.10155H61.0995V4.23047H51.8125V8.10155H54.5757V18.2286Z"
				fill={props.color || 'black'}
			/>
		</svg>
	)
}
