'use client'

import { FC, MouseEvent, PropsWithChildren, useRef } from 'react'
import styles from './button.module.scss'
import { amClickedButton } from 'events/amplitude'
import cx from 'classnames'
import { ButtonLoadingSpinner } from 'components/WebEv/Buttons/ButtonLoadingSpinner'
import Link from 'components/basic/LinkWithLocale/LinkWithLocale'
import { Url } from 'url'
import { NextLinkHref } from 'constants/types'
import { formatLink } from 'utils'
import { chevron_right, Icon, IconGenWithLabel } from 'components/LegacyIcon'
import { Colors } from 'components/WebEv/Colors/colors'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export interface ButtonInteriorProps extends ButtonProps {
	injectedClass?: string
	spinnerColor?: string
	trackMouse?: boolean
}

export interface ButtonProps extends PropsWithChildren {
	id: string
	onClick?: (e: MouseEvent) => void
	className?: string
	ariaLabel?: string
	title?: string
	type?: 'submit' | 'reset' | 'button'
	disabled?: boolean
	loading?: boolean
	href?: string | Url | NextLinkHref
	target?: string

	size?: 'compact' | 'default' | 'large'

	iconLeft?: IconGenWithLabel
	iconRight?: IconGenWithLabel
	iconColor?: string
	spinnerColor?: string
}

type ButtonSubComponent = FC<ButtonProps>

type ButtonComponent = FC<ButtonInteriorProps> & {
	Primary: ButtonSubComponent
	Secondary: ButtonSubComponent
	Outline: ButtonSubComponent
	OutlineSecondary: ButtonSubComponent
	TextLink: ButtonSubComponent
	SubtleLink: ButtonSubComponent
	IconBefore: ButtonSubComponent
	IconAfter: ButtonSubComponent
	Empty: ButtonSubComponent
}

const Primary: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.primary}
			spinnerColor={'black'}
		>
			{props.children}
		</Button>
	)
}
const Secondary: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.secondary}
			spinnerColor={'black'}
		>
			{props.children}
		</Button>
	)
}
const Outline: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.outline}
			spinnerColor={'black'}
			trackMouse
		>
			{props.children}
		</Button>
	)
}
const OutlineSecondary: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.outline_secondary}
			spinnerColor={'black'}
			trackMouse
		>
			{props.children}
		</Button>
	)
}
const TextLink: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.text}
			iconRight={chevron_right}
			iconColor={Colors.Brand.secondary.hex}
		>
			{props.children}
		</Button>
	)
}
const SubtleLink: FC<ButtonProps> = (props) => {
	return <Button {...props}>{props.children}</Button>
}
const IconBefore: FC<ButtonProps> = (props) => {
	return <Button {...props}>{props.children}</Button>
}
const IconAfter: FC<ButtonProps> = (props) => {
	return <Button {...props}>{props.children}</Button>
}

const Empty: FC<ButtonProps> = (props) => {
	return (
		<Button
			{...props}
			injectedClass={styles.empty}
		>
			{props.children}
		</Button>
	)
}

export const Button: ButtonComponent = (props) => {
	const ref = useRef(null)
	const href = typeof props.href === 'string' ? formatLink(props.href) : props.href

	const handleClick = (e: MouseEvent) => {
		amClickedButton('[children]', props.id)

		if (props.onClick) {
			props.onClick(e)
		}
	}

	const handleLinkClick = (e: MouseEvent) => {
		const analyticsName = href.toString()

		if (typeof props.onClick === 'function') {
			if (analyticsName === '#') event.preventDefault()
			props.onClick(e)
		}

		const analyticsID = props.id || 'unknown_id'
		amClickedButton(analyticsName, analyticsID)
	}

	const handleUpdateMousePos = (e: MouseEvent) => {
		if (!props.trackMouse) return
		if (ref.current) {
			const rect = ref.current.getBoundingClientRect()
			const x = e.clientX - rect.left - rect.width / 2
			const y = e.clientY - rect.top - rect.height / 2
			ref.current.style.setProperty('--x', `${x}px`)
			ref.current.style.setProperty('--y', `${y}px`)
		}
	}

	const iconLeft = props.iconLeft ? (
		<Icon
			icon={props.iconLeft}
			color={props.iconColor}
		/>
	) : null
	const iconRight = props.iconRight ? (
		<Icon
			icon={props.iconRight}
			color={props.iconColor}
		/>
	) : null

	const content = (
		<>
			<span
				style={{ opacity: props.loading ? 0 : 1, display: 'flex', alignItems: 'center', gap: '0.2rem' }}
				className={cx(styles.icon_span, 'black_spinner')}
			>
				{iconLeft}
				{props.children}
				{iconRight}
			</span>
			{props.loading && <ButtonLoadingSpinner color={props.spinnerColor} />}
		</>
	)

	if (href) {
		if (href.toString().includes('#')) {
			return (
				<AnchorLink
					onMouseMove={handleUpdateMousePos}
					ref={ref}
					id={props.id}
					className={cx(styles.base_styles, { [styles.compact]: props.size === 'compact' }, { [styles.large]: props.size === 'large' }, props.injectedClass, props.className)}
					target={props.target}
					rel={props.target === '_blank' ? 'noopener' : undefined}
					onClick={handleLinkClick}
					href={href.toString()}
					aria-label={props.ariaLabel}
				>
					{content}
				</AnchorLink>
			)
		}
		return (
			<Link
				href={href}
				onMouseMove={handleUpdateMousePos}
				ref={ref}
				id={props.id}
				className={cx(styles.base_styles, { [styles.compact]: props.size === 'compact' }, { [styles.large]: props.size === 'large' }, props.injectedClass, props.className)}
				target={props.target}
				rel={props.target === '_blank' ? 'noopener' : undefined}
				onClick={handleLinkClick}
				aria-label={props.ariaLabel}
			>
				{content}
			</Link>
		)
	}

	return (
		<button
			ref={ref}
			onMouseMove={handleUpdateMousePos}
			className={cx(styles.base_styles, { [styles.compact]: props.size === 'compact' }, { [styles.large]: props.size === 'large' }, props.injectedClass, props.className)}
			onClick={handleClick}
			disabled={props.disabled || props.loading}
			id={props.id}
			title={props.title}
			type={props.type}
			aria-label={props.ariaLabel}
		>
			{content}
		</button>
	)
}

Button.Primary = Primary
Button.Secondary = Secondary
Button.Outline = Outline
Button.OutlineSecondary = OutlineSecondary
Button.TextLink = TextLink
Button.SubtleLink = SubtleLink
Button.IconBefore = IconBefore
Button.IconAfter = IconAfter
Button.Empty = Empty
