'use client'

/* eslint-disable @next/next/no-img-element */
import { A } from 'components/basic/A'
import { Img } from 'components/basic/Img'
import { EightLogo } from 'components/EightLogo'
import { LoadingSkeleton } from 'components/LoadingSkeleton'
import { Icon } from 'components/Phantom/Icon'
import { Type } from 'components/Type'
import { Button } from 'components/WebEv/Buttons'
import { useSearchParams, useRouter } from 'next/navigation'
import styles from 'pagestyles/login.module.scss'
import Cookies from 'js-cookie'

import * as React from 'react'
import { FC, Suspense, useCallback, useEffect, useState } from 'react'

type FormType = 'login' | 'forgot-password' | 'create-account' | 'error'

interface FormProps {
	onBack?: () => void
	setFormType: (formType: FormType) => void
	createAccountMessage?: string
	errorMessage?: string
}

interface LoginFormProps extends FormProps {
	loginHeader: string
	loginSubheader: string

	successHandler: (jwt: string) => void
}

const ForgottenPasswordForm = (props: FormProps) => {
	const [successMessage, setSuccessMessage] = useState('')
	const [error, setError] = useState('')

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setSuccessMessage('')
		setError('')
		const email = (event.target as HTMLFormElement).email.value

		fetch('/api/forgot-password', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ email }),
		})
			.then(async (response) => {
				const result = await response.json()
				if (response.status === 200 && result.success) {
					setSuccessMessage('Check your email')
				} else {
					setError('An error occurred. Please try again.')
				}
			})
			.catch((error) => {
				console.error('Forgot password error', error)
				setError('An error occurred. Please try again.')
			})
	}

	if (successMessage) {
		return (
			<div className={styles.form}>
				<h1>{successMessage}</h1>
				<div className={styles.message}>Password reset instructions have been sent to your email.</div>
				<Button.Empty
					type="button"
					className={styles.cta_button}
					id="forgot-password"
					onClick={() => props.setFormType('login')}
				>
					Go back
				</Button.Empty>
			</div>
		)
	}

	return (
		<form
			action=""
			method={'POST'}
			onSubmit={handleSubmit}
			className={styles.form}
		>
			<header className={styles.login_header}>
				<Type.Headline3 as={'h1'}>Set a new password</Type.Headline3>
				<Type.Body2>Enter the email associated with your Eight Sleep account and we'll send you instructions to reset your password.</Type.Body2>
			</header>
			<div className={styles.inputs}>
				{error && <div className={styles.error}>{error}</div>}
				<input
					type="email"
					placeholder="Email"
					name="email"
					id="email"
				/>
			</div>
			<Button.Secondary
				className={styles.cta_button}
				id={'forgot-password-button'}
				type="submit"
			>
				Send
			</Button.Secondary>
			<Button.Empty
				type="button"
				className={styles.empty_button}
				id="forgot-password"
				onClick={() => props.setFormType('login')}
			>
				Return to login
			</Button.Empty>
		</form>
	)
}

const windowURLSearch = (removeParams: string[] = []) => {
	const url = new URL(window.location.href)
	removeParams.forEach((param) => url.searchParams.delete(param))
	return url.search
}

const LoginForm = (props: LoginFormProps) => {
	const { loginHeader = 'Log in to access your member only discounts', loginSubheader = 'Sign in with your Eight Sleep account to unlock your exclusive discounts' } = props
	const [passwordVisible, setPasswordVisible] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState('')

	useEffect(() => {
		if (props.errorMessage) {
			setIsLoading(false)
		}
	}, [props.errorMessage])

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setError('')
		setIsLoading(true)

		const formData = new FormData(event.currentTarget)
		const username = formData.get('email') as string
		const password = formData.get('pass') as string

		try {
			const response = await fetch('/api/auth/login', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ username, password }),
			})

			const data = await response.json()

			if (response.ok && data.access_token) {
				localStorage.setItem('authToken', data.access_token)

				// might not need to store the token in cookie, potential security issue
				// perhaps store just any data to indicate user is logged in
				Cookies.set('auth_token', data.access_token, {
					sameSite: 'Strict', // Prevents CSRF attacks
					path: '/', // Accessible throughout the app
				})
				
				localStorage.setItem('tokenData', JSON.stringify(data))
				props.successHandler(data.access_token)

				// const redirectPath = `/${queryParams.get('redirect_to') || 'pod4-member'}`
				// window.location.href = redirectPath + windowURLSearch(['redirect_to']) // This will grab the server side props from the new page
			} else {
				setError('Invalid email or password')
				setIsLoading(false)
			}
		} catch (err) {
			setError('An error occurred. Please try again.')
			console.error('Login error:', err)
			setIsLoading(false)
		}
	}

	return (
		<form
			onSubmit={handleSubmit}
			className={styles.form}
		>
			<header className={styles.login_header}>
				<Type.Headline3 as={'h1'}>{loginHeader}</Type.Headline3>
				<LoadingSkeleton
					height={'3em'}
					width={'100%'}
				>
					<Type.Body2>{loginSubheader}</Type.Body2>
				</LoadingSkeleton>
			</header>

			<div className={styles.inputs}>
				<input
					type="email"
					placeholder="Email"
					name="email"
					id="email"
				/>

				<label>
					<input
						type={passwordVisible ? 'text' : 'password'}
						placeholder="Password"
						name="pass"
						id="pass"
					/>
					<Button.Empty
						type={'button'}
						id={'password-visibility-toggle'}
						onClick={() => setPasswordVisible((prev) => !prev)}
						className={styles.password_visibility_toggle}
					>
						<Icon
							name={passwordVisible ? 'EyeNewSlashLight' : 'EyeNewOpenLight'}
							color={'white'}
							size={24}
						/>
					</Button.Empty>
				</label>
				{error && <Type.Body2 className={styles.error}>{error}</Type.Body2>}
			</div>

			<Button.Secondary
				className={styles.cta_button}
				id={'login-button'}
				type="submit"
				disabled={isLoading}
				loading={isLoading}
			>
				{isLoading ? '' : 'Sign in'}
			</Button.Secondary>
			<Button.Empty
				type="button"
				className={styles.empty_button}
				id="forgot-password"
				onClick={() => props.setFormType('forgot-password')}
			>
				I forgot my password
			</Button.Empty>
			{props.createAccountMessage && (
				<Button.Empty
					type="button"
					className={styles.empty_button}
					id="create-account"
					onClick={() => props.setFormType('create-account')}
				>
					Create an account
				</Button.Empty>
			)}
		</form>
	)
}

const CreateAccountForm = (props: FormProps) => {
	return (
		<div className={styles.form}>
			<Button.Empty
				id="create-account-back"
				onClick={() => {
					props.setFormType('login')
					props.onBack?.()
				}}
				className={styles.back_button}
			>
				<Icon
					name="ChevronLeftDark"
					color="white"
				/>{' '}
				Back
			</Button.Empty>
			<header className={styles.login_header}>
				<Type.Headline3 as={'h1'}>Create an account</Type.Headline3>
				<Type.Body2>{props.createAccountMessage}</Type.Body2>
			</header>
		</div>
	)
}

const ErrorForm = (props: FormProps) => {
	return (
		<div className={styles.form}>
			<Button.Empty
				id="create-account-back"
				onClick={() => {
					props.setFormType('login')
					props.onBack?.()
				}}
				className={styles.back_button}
			>
				<Icon
					name="ChevronLeftDark"
					color="white"
				/>
				Back
			</Button.Empty>
			<header className={styles.login_header}>
				<Type.Body1>{props.errorMessage}</Type.Body1>
			</header>
		</div>
	)
}

export type LoginFormWrapperProps = Omit<LoginFormProps, 'setFormType'>

export const LoginFormWrapper = (props: LoginFormWrapperProps) => {
	const [formType, setFormType] = useState<FormType>('login')

	useEffect(() => {
		if (props.errorMessage) {
			setFormType('error')
		}
	}, [props.errorMessage])

	return (
		<div className={styles.login_modal}>
			<div data-visible={formType === 'login'}>
				<LoginForm
					setFormType={setFormType}
					{...props}
				/>
			</div>
			<div data-visible={formType === 'forgot-password'}>
				<ForgottenPasswordForm setFormType={setFormType} />
			</div>
			<div data-visible={formType === 'create-account'}>
				<CreateAccountForm
					setFormType={setFormType}
					{...props}
				/>
			</div>
			<div data-visible={formType === 'error'}>
				<ErrorForm
					setFormType={setFormType}
					{...props}
				/>
			</div>
		</div>
	)
}

interface GenericLoginProps {
	successHandler: (jwt: string) => void
	onBack?: () => void

	loginHeader?: string
	loginSubheader?: string
	createAccountMessage?: string
	errorMessage?: string
}

export const GenericLoginPage: FC<GenericLoginProps> = (props) => {
	const {
		successHandler,
		onBack,
		loginHeader = 'Sign in to your Eight Sleep account',
		loginSubheader = 'Sign in with your Eight Sleep account to update your Sleep Elixir order',
		createAccountMessage,
		errorMessage,
	} = props

	useEffect(() => {
		document.documentElement.style.overflow = 'hidden'

		return () => {
			document.documentElement.style.overflow = 'hidden scroll' // Current default
		}
	}, [])

	return (
		<div className={styles.container}>
			<nav>
				<A
					href={'/'}
					id={'logo'}
				>
					<EightLogo color="white" />
				</A>
			</nav>
			<Img
				src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/login-bg-feb-2025.jpg'}
				sources={[
					{
						src: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/login-bg-feb-2025-mobile.jpg',
						mediaQuery: '(max-width: 600px)',
					},
				]}
				alt={'Woman lying in bed'}
				dprHeight={1600}
				className={styles.image}
				objectFit={'cover'}
			/>
			<div className={styles.login_container}>
				<LoginFormWrapper
					successHandler={successHandler}
					loginHeader={loginHeader}
					loginSubheader={loginSubheader}
					createAccountMessage={createAccountMessage}
					errorMessage={errorMessage}
					onBack={onBack}
				/>
			</div>
		</div>
	)
}

const LoginPage = () => {
	return (
		<Suspense fallback={null}>
			<LoginPageBody />
		</Suspense>
	)
}

const LoginPageBody = () => {
	const queryParams = useSearchParams()
	const router = useRouter()

	const redirectTo = queryParams.get('redirect_to')

	const loginSuccess = useCallback(() => {
		const redirectPath = `/${redirectTo || 'pod4-member'}/`
		router.push(redirectPath + windowURLSearch(['redirect_to']))
	}, [redirectTo, router])

	return (
		<GenericLoginPage
			successHandler={loginSuccess}
			loginHeader={'Log in to your Eight Sleep account'}
			loginSubheader={'Sign in with your Eight Sleep account to access exclusive discounts and refer friends'}
		/>
	)
}

export default LoginPage
